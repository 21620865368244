.change-log {
    padding-left: 0;
    margin-left: 0;
}

.timeline-decorator::before {
    content: "";
    width: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 52px;
    z-index: 10;
}

.timeline-decorator > span {
    z-index: 20;
}

@media (max-width: 767px) {
    .change-description {
        margin-top: 2px;
        font-size: 0.9em;
    }
}

@media (min-width: 768px) {
    .timeline-decorator::before {
        left: calc((65px / 2) + 58px);
    }
    .change-log {
        margin-left: 90px;
        padding-left: 2em;
    }
}

hr {
    border-color:rgba(255, 255, 255, 0.1)
}

.container-new {
    max-width: 980px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1012px) {
    .container-new {
        max-width: 1012px;
    }
}

@media (min-width: 1280px) {
    .container-new {
        max-width: 1280px;
    }
}

.btn-outline-white {
    padding: .9em 1.25em;
    color: #fff;
    background: transparent;
    border-color: rgba(255, 255, 255, 0.5);
    border-radius: .3em;
}

.btn-outline-white:hover, .btn-outline-white:active {
    color: #24292e;
    text-decoration: none;
    background: #fff;
    border-color: #fff;
}

.btn-outline-purple {
    padding: .9em 1.25em;
    color: #9f7be1;
    background: transparent;
    border-color: rgba(159, 123, 225, 0.5);
    border-radius: .3em;
}

.btn-outline-purple:hover, .btn-outline-purple:active {
    color: #24292e;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
    background: #9f7be1;
    border-color: #9f7be1;
}

.border-translucent-white {
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.release-note header h2 {
    max-width: 100% !important;
}

.change-badge {
    background-color: #0366d6;
    display: inline;
    flex: 0 0 75px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 3px;
    margin-right: 8px;
    padding: 2px 8px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 3px;
    z-index: 20;
}

.change-badge-new, .change-badge-added {
    background-color: #28a745;
}

.change-badge-improved {
    background-color: #6f42c1;
}

.change-badge-fixed {
    background-color: #0366d6;
}

.change-badge-removed {
    background-color: #d73a49;
}

.version-badge {
    width: 65px;
    text-align: center;
}