body.theme-dark {
    background-color: #24292e;

    #release-notes, .project-edit {
        color: rgba(255, 255, 255, 0.85);

    }
    .release-note:first-of-type .timeline-decorator::before {
        background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 50px);
    }
    .release-note:last-of-type .timeline-decorator::before {
        background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    }
    .timeline-decorator::before {
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    }
}
