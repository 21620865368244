
body.theme-light {
    background-color: #fff;

    .change-badge, .timeline-decorator {
        color: #fff;
        z-index: 20;
    }
    .release-note:first-of-type .timeline-decorator::before {
        background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1) 50px);
    }
    .release-note:last-of-type .timeline-decorator::before {
        background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
    }
    .timeline-decorator::before {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        z-index: 10;
    }

}