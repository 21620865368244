// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Styles
@import 'styles';

// Bootstrap Icons
@import '~bootstrap-icons/font/bootstrap-icons';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Themes
@import 'theme';
@import 'theme-dark';
@import 'theme-light';